import React from 'react';
import './src/styles/global.css';
import { IdentityContextProvider } from 'react-netlify-identity';
import { QueryClient, QueryClientProvider } from 'react-query';

const url = 'https://www.lovelivedance.com';

export const wrapRootElement = ({ element }) => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<IdentityContextProvider url={url}>{element}</IdentityContextProvider>
		</QueryClientProvider>
	);
};
